import React from "react"

import styles from "./features-row.module.scss"

const FeaturesRow = ({ title, features }) => (
  <div className={`container ${styles.features}`}>
    <div className={styles.header}>
      <h2 className={styles.title} dangerouslySetInnerHTML={{__html:title}}></h2>
    </div>
    <div className={styles.row}>
      {features.map(feature =>
        <div key={feature.name}
             className={styles.feature}>
          <div className={styles.image}>
            <img src={feature.image} alt={feature.name}/>
          </div>
          <div className={styles.info}>
            <h3 className={styles.title}>{feature.name}</h3>
            <div className={styles.description} dangerouslySetInnerHTML={{__html:feature.text}}></div>
          </div>
        </div>,
      )}
    </div>
  </div>
)

export default FeaturesRow