import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import ReviewComponent from "../../components/pages/main/review"
import FeaturesComponent from "../../components/pages/main/landing-features"
import FeaturesRow from "../../components/pages/features/row"
import styles from "./feature.module.scss"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const tasksAccordionData = [
  {
    name: "whatisit",
    title: "Что такое планировщик задач в CRM-системе?",
    content: `
      <p>Планировщик задач в CRM-системе Параплан - это отдельный раздел системы для упрощение работы с задачи. В данном разделе можете поставить любую задачу, в том числе по лиду или ученику. Отслеживать, на каком этапе задача и проверять ее дедлайн, выставляя фильтры по задачам. Уведомление о поставленной задаче придет ответственному сотруднику на электронную почту, а также в самой системе рядом с названием раздела “Задачи” пользователь увидит количество у него активных задач.</p>
    `,
  },
  {
    name: "help",
    title: "Как планировщик задач в CRM помогает увеличить продажи?",
    content: `
      <p>Планировщик задач помогает навести порядок в работе детского центра. CRM-система напомнит о звонке или встрече, менеджеры не забудут о клиенте даже при высокой нагрузке. Менеджер по продажам может сразу поставить задачу о звонке клиенту после пробного занятия, чтобы предложить ему купить абонемент. Ничего не нужно держать в голове или дублировать в другую программу. Наглядный список задач отображается у каждого ответственного менеджера. Процесс работы с любым клиентом или лидом становится прозрачным, и любое отклонение от регламента станет заметным.</p>
    `,
  },
  {
    name: "control",
    title: "Как таск-менеджер в CRM позволяет руководителю детского центра контролировать сотрудников?",
    content: `
      <p>С помощью удобных фильтров в разделе “Задачи” CRM-системы вы сможете находить любую задачу и отслеживать проводимую работу по ней. Вы всегда будет знать, что получите результат, даже если команда работает на удаленке или онлайн. Планировщик позволит распределить задачи по времени и ответственным сотрудникам сразу после планирования. Таким образом, ни одна идея не потеряется, а коллеги сразу приступят к реализации планов. Кроме этого, не требуется постоянно “пинать” сотрудников и устно уточнять на совещаниях, как продвигается процесс по задач, так как всю информацию о статусе вы сможете увидеть в карточке задачи. Расставляйте задачи с помощью встроенных статусов по приоритету, чтобы срочные задачи выполнялись в первую очередь, и нагрузка на сотрудников была равномерной.</p>
    `,
  }
]

const TasksFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Планировщик задач', url: ''}
  ];
  return (
    <Layout className={styles.tasks} headerColor={"#E75D49"} page={"tasks"}>
      <SEO
        title="Попробуйте планировщик задач в CRM-системе Параплан"
        keywords={[`постановка задач`, `задачи сотрудникам`]}
        description="Оцените возможности планировщика задач в CRM системе Параплан. Ставьте задачи сотрудникам и контролируйте процессы в удаленном режиме."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        markDark={true}
        name={"tasks"}
        title='Ставьте задачи сотрудникам через планировщик'
        description='Назначить встречу, поздравить с днем рождения, обзвонить должников - теперь не нужно все держать в голове.'
        color='#E75D49'
        image={require("../../images/cover/tasks.png")}/>
      <Breadcrumb crumbs={ crumbs } arrowcolor={"red"}/>
      <AboutComponent
        title='Тип задач'
        description='Онлайн таск-менеджер содержит основные типы задач, которые помогут вам в работе: звонок, письмо, встреча, напоминание и пр. Также можно поставить задачу в произвольной форме.'
        image={require("../../images/screenshot/tasks/tasks_04@2x.png")}
        imageWidth={670}
        imageHeight={398}
        bgSize={"small"}
        reverse={true}
        color='#319DED'/>
      <AboutComponent
        title='Приоритет задач'
        description='Важные дела теперь не отложишь на завтра. В системе управления задачами выставите приоритет (низкий, средний, высокий) и план на день у вас в кармане.'
        image={require("../../images/screenshot/tasks/tasks_02@2x.png")}
        imageWidth={669}
        imageHeight={360}
        bgSize={"small"}
        color='#FFC800'/>
      <AboutComponent
        title='Статус и срок исполнения задач'
        description='Статус задач (назначена, в работе, выполнена, отклонена) поможет легко отследить на каком этапе выполнения находится задача. '
        image={require("../../images/screenshot/tasks/tasks_03@2x.png")}
        imageWidth={514}
        imageHeight={382}
        bgSize={"wide"}
        reverse={true}
        color='#762BDA'/>
      {/*<FreeSummer topInd="40"/>*/}
      <Discount />
      <FeaturesRow
        title={"Планировщик задач поможет не забыть о <br/> важных делах и выполнить работу во время"}
        features={[
          {
            name: "Удобные уведомления",
            text: "Поставьте задачу себе или сотруднику. Администратор получит уведомление на электронную почту с содержанием задачи.",
            image: require("../../images/icons/tasks_notification.svg"),
          },
          {
            name: "Держите список задач на виду",
            text: "Все актуальные задачи находятся <br/> на главном экране, так что их будет сложно <br/> не заметить.",
            image: require("../../images/icons/tasks_list.svg"),
          },
          {
            name: "Раздел задачи",
            text: "Здесь вы сможете создать задачу, а также найти или переназначить ее для другого сотрудника.",
            image: require("../../images/icons/tasks_done.svg"),
          },
        ]}/>
      <ReviewComponent className={styles.review}
                       author={"Савр Мучкаев, детский центр «Азбука»"}>
        Не всегда понятно, какая тактика сработает с клиентами, а какая нет. Но с Параплан мы смогли найти свой подход к
        каждому клиенту, который удвоил наш доход и посещаемость центра.
      </ReviewComponent>
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent/>
      <FeatureAccordion
            data={tasksAccordionData}
            title='Встроенный планировщик задач в CRM-системе'
            descr='В CRM-системе для детских и учебных центров Параплан есть раздел для планирования и ведения рабочих и личных задач, встреч, звонков для педагогов, администраторов и руководителей организации.
            Через планировщик задач владелец бизнеса может ставить задачи себе и сотрудникам. При создании задачи сотруднику придет уведомление на электронную почту, а также внутри CRM-системы у конкретного сотрудника будет отображено количество незавершенных задач. Ответственный менеджер детского центра будет знать всю необходимую информацию: срок выполнения задачи, ее описание, по какому ученику или лиду нужно выполнить задача. Для контроля выполнения задач предусмотрено несколько системных статусов.
            Планировщик функционально связан с различными разделами в CRM-системе, вы сможете привязывать планы и задачи к клиентам, лидам и педагогам, и задача будет отображена в их профилях. Наглядная система управления задачами позволит вести сотни клиентов, всем уделить внимание и не забыть ничего важного.
            '
      />
    </Layout>
  )
}

export default TasksFeaturePage
